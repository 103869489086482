<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>基本资料</el-breadcrumb-item>
      <el-breadcrumb-item>供应商信息</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:24,offset:0}" :md="{span:12,offset:0}" :lg="{span:12,offset:0}" :xl="{span:6,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.s_company_name" clearable @clear="inputchange" @keyup.native.enter="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="AddSupplierSelect" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="supplierlist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="供应商编码" prop="s_code" show-overflow-tooltip></el-table-column>
        <el-table-column label="名称" prop="s_company_name" show-overflow-tooltip width="200px"></el-table-column>
        <el-table-column label="详细地址" prop="region" show-overflow-tooltip width="200px"></el-table-column>
        <el-table-column label="联系人" prop="s_name" show-overflow-tooltip></el-table-column>
        <el-table-column label="联系人电话" prop="s_phone"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button type="primary" size="mini" v-if="is_edit" icon="el-icon-edit"
                       @click="supplierEditDialog(scope.row.s_id)">
            </el-button>
            <!--删除-->
            <el-button type="danger" size="mini" v-if="is_del" icon="el-icon-delete-solid"
                       @click="removeSupplierById(scope.row.s_id)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!--  添加对话框  -->
    <el-dialog
        title="添加供应商"
        :visible.sync="addSupplierDialogVisible"
        width="60%"
        @close="addSupplieDialoghandleClose">

      <el-form ref="addsupplierFormRef" :model="addsupplierForm" label-width="100px"
               :rules="addsupplierFormRules" >
        <el-form-item label="供应商名称" prop="s_company_name">
          <el-input v-model="addsupplierForm.s_company_name"></el-input>
        </el-form-item>
        <el-form-item label="选择地址" prop="region">
          <el-cascader
              v-model="addsupplierForm.region" clearable @change="addSupplierChanged"
              :options="listarea" :props="cascaderProps"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="s_detail">
          <el-input v-model="addsupplierForm.s_detail"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="s_name">
          <el-input v-model="addsupplierForm.s_name"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="s_phone">
          <el-input v-model="addsupplierForm.s_phone"></el-input>
        </el-form-item>

        <el-form-item label="座机号码" prop="s_mobile">
          <el-input v-model="addsupplierForm.s_mobile"></el-input>
        </el-form-item>

        <el-form-item label="职位" prop="s_job">
          <el-input v-model="addsupplierForm.s_job"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="s_email">
          <el-input v-model="addsupplierForm.s_email"></el-input>
        </el-form-item>
        <el-form-item label="开户名称" prop="s_account_name">
          <el-input v-model="addsupplierForm.s_account_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="s_deposit_bank">
          <el-input v-model="addsupplierForm.s_deposit_bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="s_account_bank">
          <el-input v-model="addsupplierForm.s_account_bank"></el-input>
        </el-form-item>
<!--        <el-form-item label="发票抬头" prop="s_invoice_title">-->
<!--          <el-input v-model="addsupplierForm.s_invoice_title"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="纳税人识别号" prop="s_taxpayer">
          <el-input v-model="addsupplierForm.s_taxpayer"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="s_remarks">
          <el-input v-model="addsupplierForm.s_remarks"></el-input>
        </el-form-item>


      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="addSupplierDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSupplier" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!--  编辑对话框  -->
    <el-dialog
        title="编辑供应商"
        :visible.sync="editSupplierDialogVisible"
        width="60%"
        @close="editSupplieDialoghandleClose">

      <el-form ref="editsupplierFormRef" :model="editsupplierForm" label-width="100px"
               :rules="editsupplierFormRules" >
        <el-form-item label="供应商名称" prop="s_company_name">
          <el-input v-model="editsupplierForm.s_company_name"></el-input>
        </el-form-item>
        <el-form-item label="选择地址" prop="region">
          <el-cascader
              v-model="editsupplierForm.region" clearable @change="editSupplierChanged"
              :options="listarea" :props="cascaderProps"
          ></el-cascader>
        </el-form-item>

        <el-form-item label="详细地址" prop="s_detail">
          <el-input v-model="editsupplierForm.s_detail"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="s_name">
          <el-input v-model="editsupplierForm.s_name"></el-input>
        </el-form-item>

        <el-form-item label="手机号" prop="s_phone">
          <el-input v-model="editsupplierForm.s_phone"></el-input>
        </el-form-item>

        <el-form-item label="座机号码" prop="s_mobile">
          <el-input v-model="editsupplierForm.s_mobile"></el-input>
        </el-form-item>

        <el-form-item label="职位" prop="s_job">
          <el-input v-model="editsupplierForm.s_job"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="s_email">
          <el-input v-model="editsupplierForm.s_email"></el-input>
        </el-form-item>
        <el-form-item label="开户名称" prop="s_account_name">
          <el-input v-model="editsupplierForm.s_account_name"></el-input>
        </el-form-item>
        <el-form-item label="开户银行" prop="s_deposit_bank">
          <el-input v-model="editsupplierForm.s_deposit_bank"></el-input>
        </el-form-item>
        <el-form-item label="银行账号" prop="s_account_bank">
          <el-input v-model="editsupplierForm.s_account_bank"></el-input>
        </el-form-item>
<!--        <el-form-item label="发票抬头" prop="s_invoice_title">-->
<!--          <el-input v-model="editsupplierForm.s_invoice_title"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="纳税人识别号" prop="s_taxpayer">
          <el-input v-model="editsupplierForm.s_taxpayer"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="s_remarks">
          <el-input v-model="editsupplierForm.s_remarks"></el-input>
        </el-form-item>


      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editSupplierDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSupplier" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      editloading:false,
      querInfo: {
        page: 1,
        limit: 10,
        s_company_name:''
      },
      total:0,
      //页面权限
      SupplierJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      //供应商信息
      supplierlist: [],
      // 省市区信息
      listarea: [],
      //控制添加对话框的展示与隐藏
      addSupplierDialogVisible:false,
      //控制编辑对话框的展示与隐藏
      editSupplierDialogVisible:false,
      //添加的form表单数据
      addsupplierForm:{},
      //编辑的form表单数据
      editsupplierForm:{},
      //选中的省市区 添加
      selectListarea:[],
      //选中的省市区 编辑
      editselectListarea:[],
      //三级联动的配置
      cascaderProps: {
        expandTrigger: 'hover',
        // checkStrictly:true,
        value: 'la_id',
        label: 'la_name',
        children: 'children'
      },
      //添加form表单的验证
      addsupplierFormRules:{
        s_company_name:[
          {required: true, message: '请输入供应商的名称', trigger: 'blur'}
        ],
        // s_detail:[
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        // s_name:[
        //   {required: true, message: '请输入联系人', trigger: 'blur'}
        // ],
        // s_phone:[
        //   {required: true, message: '请输入手机号', trigger: 'blur'}
        // ],
        // s_mobile:[
        //   {required: true, message: '请输入座机号码', trigger: 'blur'}
        // ],
        // s_job:[
        //   {required: true, message: '请输入职位', trigger: 'blur'}
        // ],
        // s_email:[
        //   {required: true, message: '请输入邮箱', trigger: 'blur'}
        // ],
        // s_account_name:[
        //   {required: true, message: '请输入开户名称', trigger: 'blur'}
        // ],
        // s_deposit_bank:[
        //   {required: true, message: '请输入开户银行', trigger: 'blur'}
        // ],
        // s_account_bank:[
        //   {required: true, message: '请输入银行账号', trigger: 'blur'}
        // ],
        // s_invoice_title:[
        //   {required: true, message: '请输入发票抬头', trigger: 'blur'}
        // ],
        // s_remarks:[
        //   {required: true, message: '请输入备注', trigger: 'blur'}
        // ],
        region:[
          {required: true, message: '请选择地址', trigger: 'change'}
        ]
      },
      //添加form表单的验证
      editsupplierFormRules:{
        s_company_name:[
          {required: true, message: '请输入供应商的名称', trigger: 'blur'}
        ],
        // s_detail:[
        //   {required: true, message: '请输入详细地址', trigger: 'blur'}
        // ],
        // s_name:[
        //   {required: true, message: '请输入联系人', trigger: 'blur'}
        // ],
        // s_phone:[
        //   {required: true, message: '请输入手机号', trigger: 'blur'}
        // ],
        // s_mobile:[
        //   {required: true, message: '请输入座机号码', trigger: 'blur'}
        // ],
        // s_job:[
        //   {required: true, message: '请输入职位', trigger: 'blur'}
        // ],
        // s_email:[
        //   {required: true, message: '请输入邮箱', trigger: 'blur'}
        // ],
        // s_account_name:[
        //   {required: true, message: '请输入开户名称', trigger: 'blur'}
        // ],
        // s_deposit_bank:[
        //   {required: true, message: '请输入开户银行', trigger: 'blur'}
        // ],
        // s_account_bank:[
        //   {required: true, message: '请输入银行账号', trigger: 'blur'}
        // ],
        // s_invoice_title:[
        //   {required: true, message: '请输入发票抬头', trigger: 'blur'}
        // ],
        // s_remarks:[
        //   {required: true, message: '请输入备注', trigger: 'blur'}
        // ],
        region:[
          {required: true, message: '请选择地址', trigger: 'change'}
        ]
      }
    }
  },
  created() {
    this.getSupplierList()
    this.getSupplierJurisdiction()
  },
  methods: {
    //获取权限接口
    async getSupplierJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.SupplierJurisdiction=res.data
      this.SupplierJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    // 获取供应商
    async getSupplierList() {
      this.loading=true
      const {data: res} = await this.$http.get('supplier/getList',{params: this.querInfo})
      if (res.code != 200) return this.$message.error(res.msg)
      this.supplierlist = res.data.data
      this.total = res.data.total
      this.loading=false

    },
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getSupplierList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getSupplierList()
    },
    //获取省市区
    async getWarehouseArea() {
      const {data: res} = await this.$http.get('listarea')
      if (res.code != 200) return this.$message.error('获取省市区数据失败')
      this.listarea = res.data
    },
    inputchange(){
      this.querInfo.page=1
      this.getSupplierList()
    },
    //添加供应商
    AddSupplierSelect() {
      this.addSupplierDialogVisible=true
      this.getWarehouseArea()
    },
    //监听添加对话框的显示与隐藏
    addSupplieDialoghandleClose(){
      this.$refs.addsupplierFormRef.resetFields()
      this.selectListarea = []
      this.addsupplierForm={}
    },
    //点击确定提交添加form表单
    addSupplier(){
      this.$refs.addsupplierFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('supplier/add', this.addsupplierForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success("添加成功")
        this.addSupplierDialogVisible = false
        this.getSupplierList()
      })
    },
    //点击编辑弹出对话框
    async supplierEditDialog(id){
      this.getWarehouseArea()
      this.editSupplierDialogVisible=true
      const {data: res} = await this.$http.get(`supplier/find/${id}` )
      if (res.code != 200) return this.$message.error(res.msg)
      this.editsupplierForm = res.data
      this.editselectListarea=res.data.region
    },
    //添加 监听级联选择器 选中节点变化时触发
    addSupplierChanged(){
      this.addsupplierForm.s_province=this.addsupplierForm.region[0]
      this.addsupplierForm.s_city=this.addsupplierForm.region[1]
      this.addsupplierForm.s_area=this.addsupplierForm.region[2]
    },
    //编辑 监听级联选择器 选中节点变化时触发
    editSupplierChanged(){
      this.editsupplierForm.s_province=this.editsupplierForm.region[0]
      this.editsupplierForm.s_city=this.editsupplierForm.region[1]
      this.editsupplierForm.s_area=this.editsupplierForm.region[2]
    },
    //监听编辑对话框的显示与隐藏
    editSupplieDialoghandleClose(){

    },
    //点击提交
    editSupplier() {
      this.$refs.editsupplierFormRef.validate(async valid => {
        if (!valid) return
        this.editloading=true
        const {data: res} = await this.$http.post('supplier/edit',
            this.editsupplierForm)
        this.editloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.editSupplierDialogVisible=false
        this.getSupplierList()

      })
    },
    //点击删除
    async removeSupplierById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`supplier/del/${id}`)
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('删除成功')
      this.getSupplierList()
    },


  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog{
  height: 75vh;
  overflow: auto;
}
/deep/::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
/deep/::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ffffff;
  border-radius: 3px;
}
/*定义滑块颜色、内阴影及圆角*/
/deep/::-webkit-scrollbar-thumb{
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #E8E8E8;
}
</style>
